import React from "react";

import img from "../../assets/news/articles/Space_Engines_7-31-23.png";
import img3 from "../../assets/company/team/dawn.png";
import img4 from "../../assets/news/articles/USSF_contract.png";
import img5 from "../../assets/news/articles/Asteroid_mining.png";
import img6 from "../../assets/news/articles/ground-systems.jpg";
import img7 from "../../assets/news/articles/space_mining.png";
import img8 from "../../assets/news/articles/removal_awareness.png";
import img9 from "../../assets/news/articles/gold_rush.png";
import img10 from "../../assets/news/articles/mining_the_skies.png";
import img11 from "../../assets/news/articles/spaceflight.png";
import img12 from "../../assets/news/articles/detection_tool.png";
import img13 from "../../assets/news/articles/Ominvore Test With Plume.png";
import img14 from "../../assets/news/articles/space_objects.png";
import img15 from "../../assets/capabilities/maneuver/maneuver-2.png";
import img16 from "../../assets/news/articles/mother_lode.png";
import img17 from "../../assets/news/articles/space_tugs.png";
import img18 from "../../assets/news/articles/pentagon.png";
import img19 from "../../assets/news/articles/boldly_go.png";
import img20 from "../../assets/news/articles/whosincharge.png";
import img21 from "../../assets/news/articles/space_rocks.png";
import img22 from "../../assets/news/articles/capturBagMid.png";
import img23 from "../../assets/products/theia/theia-3.png";
import img24 from "../../assets/news/articles/capturBagMid.png";
import img25 from "../../assets/news/articles/Hive Capture Bag Demo Render.png"
import img26 from "../../assets/news/articles/mineAsteroids.png"
import img27 from "../../assets/news/articles/gold_rush.png"
import img28 from "../../assets/news/articles/TKO close up at night.png"
import img29 from "../../assets/news/articles/peterGarretson.png"
import img30 from "../../assets/news/articles/TKO for IG post.png"
import newsData from './newsData.json'

const imgList = [
    img30,
    img29,
    img28,
    img27,
    img26,
    img25,
    img24,
    img23,
    img22,
    img,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
]

for (let i = 0; i < newsData.length; ++i) {
    newsData[i].img = imgList[i];
}

const News = (props) => {
    let key = 0;
    return (
        <div id='articles' className="news-page capa-container" >
            <h1>Articles</h1>

            {newsData.map((data) => {
                return (
                    <div key={++key} >
                        <a target="_blank" href={data.link}>
                            <div className="img-container" ><img src={data.img}></img></div>
                        </a>
                        <a target="_blank" href={data.link}>
                            <h2>{data.title}</h2>
                        </a>
                        <p>{data.date}</p>
                        <p>{data.from}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default News;
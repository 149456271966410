import React, { useState, useRef } from "react";

import img from '../../assets/news/appearances/hyper_space.png';
import img2 from '../../assets/news/appearances/SpaceIndustrialBase.png';
import img3 from '../../assets/news/appearances/spaceExpo.png';
import img4 from '../../assets/news/appearances/spaceComm.png';
import img5 from '../../assets/news/appearances/siliconValley.png';
import img6 from '../../assets/news/appearances/smallSat.png';
import img7 from '../../assets/news/appearances/spaceIndustrial.png';
import img8 from '../../assets/news/appearances/spacesettlement.png';
import img9 from '../../assets/news/appearances/futureOfTransport.png';
import img10 from '../../assets/news/appearances/SpaceCenterHouston.png';

const Speaking = (props) => {
    let key = -1;
    return (
        <div id='speaking' className="news-page capa-container" >
            <h1>Public Appearances</h1>

            {dataSet.map((data) => {
                ++key;
                return (
                    <div key={key} >
                        <a href={data.link} target="_blank" >
                            <div className="img-container" ><img src={data.img}></img></div>
                        </a>
                        <a href={data.link} target="_blank" >
                            <h2>{data.title}</h2>
                        </a>
                        <p>{data.date}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default Speaking;


const dataSet = [

    {
        img: img10,
        link: "https://www.youtube.com/watch?v=ecJRpAkLp08",
        title: "Engineering the Future of Space with TransAstra | Space Center Houston",
        date: "November 21, 2024",
    },
    {
        img: img,
        link: "https://vimeo.com/830289119/acd7a76627",
        title: "Hyperspace Challenge - From Brand to Infinity: Startup's Guide to Marketing",
        date: "May 25, 2023",
    },
    {
        img: img2,
        link: "https://www.newspacenm.org/space-industrial-base/",
        title: "Joel Sercel speaks at State of the Space Industrial Base 2023",
        date: "May 18, 2023",
    },
    {
        img: img3,
        link: "",
        title: "Joel Sercel speaks at Space Tech Expo - Probing Emerging Technologies for Futer Space Exploration and Downstream Applications",
        date: "May 02, 2023",
    },
    {
        img: img4,
        link: "https://expo.spacecomexpo.com/spc23/Public/SessionDetails.aspx?FromPage=Sessions.aspx&SessionID=3524&SessionDateID=158",
        title: "Joel Sercel speaks at Space Com 2023 - Space Traffic Managment",
        date: "February 23, 2023",
    },
    {
        img: img5,
        link: "https://2022.milsatshow.com/speakers/dr-joel-sercel/",
        title: "Joel Sercel speaks at Silicon Valley Space Week Symposium",
        date: "October 13, 2022",
    },
    {
        img: img6,
        link: "https://2022.smallsatshow.com/agenda/",
        title: "Small Sat Symposium",
        date: "February 09, 2022",
    },
    {
        img: img7,
        link: "http://aerospace.csis.org/wp-content/uploads/2020/07/State-of-the-Space-Industrial-Base-2020-Report_July-2020_FINAL.pdf",
        title: "State of the Space Industrial Base 2020",
        date: "May 05, 2020",
    },
    {
        img: img8,
        link: "https://spacesettlementsummit2019.nss.org/home/detailed-schedule/",
        title: "Space Settlement Summit",
        date: "November 14, 2019",
    },
    {
        img: img9,
        link: "https://www.globenewswire.com/en/news-release/2018/04/26/1488369/6837/en/The-Wall-Street-Journal-Future-of-Everything-Festival-Presents-The-Future-of-Transportation-May-9th-in-New-York-City.html",
        title: "Wall St. Journal Future of Everything Festival: The Future of Transportation",
        date: "May 19, 2018",
    },
]